import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { navigationRoutes, technicalRoutes } from './routes.js';

export const RouteRenderer = ({ config, features, isAuthenticated }) => {
  const renderNavigationRoutes = () => {
    return navigationRoutes.flatMap((section) =>
      section.routes
        .filter((route) => {
          if (section.config_required && !config?.[section.config_required])
            return false;
          if (route.feature_required && !features?.[route.feature_required])
            return false;
          return true;
        })
        .map((route) => (
          <Route
            key={route.url_path}
            path={route.url_path}
            element={<route.component />}
          />
        ))
    );
  };

  const renderTechnicalRoutes = () => {
    return technicalRoutes.map((route) => (
      <Route
        key={route.url_path}
        path={route.url_path}
        element={
          !isAuthenticated && !route.public ? (
            <Navigate to="/login" />
          ) : (
            <route.component />
          )
        }
      />
    ));
  };

  return (
    <Routes>
      {isAuthenticated ? renderNavigationRoutes() : null}
      {renderTechnicalRoutes()}
      <Route
        path="*"
        element={
          isAuthenticated ? <Navigate to="/" /> : <Navigate to="/login" />
        }
      />
    </Routes>
  );
};
