import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Skeleton from 'tiny-skeleton-loader-react';
import fleetLogo from '../../assets/images/logos/FleetWhite.png';
import { logout, setUserFactory } from '../../reducer/actions';
import SelectFilter from '../../../src/components/agv-header-select';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import useGetRequest from '../../api/useGetRequest';
import fleetApi from '../../services/api.service';

export default function Menu({ isAuthenticated, children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clientFeatures = useSelector((state) => state.features);

  const [factory, setFactory] = useState('');

  const signOut = () => {
    localStorage.removeItem('fleetToken');
    localStorage.removeItem('fleetClientId');
    dispatch(logout());
    navigate('/login');
  };

  const {
    data: ownUserData,
    isLoading: ownUserIsLoading,
    error: ownUserError,
    mutate: refetchOwnUser,
  } = useGetRequest('/users/me');

  useEffect(() => {
    const userCurrentFactory = ownUserData?.config?.factory_name;
    if (userCurrentFactory != undefined) {
      setFactory(userCurrentFactory);
      dispatch(setUserFactory(userCurrentFactory));
    }
  }, [ownUserData, dispatch]);

  const changeUserFactory = async (value) => {
    setFactory(value);
    try {
      const response = await fleetApi.put('/users/config', {
        factory_name: value,
      });
      if (response.status === 200) {
        await refetchOwnUser();
        window.location.reload();
      }
    } catch (error) {
      console.error(
        'Erreur lors de la mise à jour de la configuration utilisateur:',
        error
      );
    }
  };

  return (
    <div className="tw-h-full tw-pt-7 tw-flex tw-flex-col">
      <div className="tw-w-full tw-flex tw-justify-center tw-items-center">
        <div className="tw-w-16">
          <Link to={isAuthenticated ? '/' : '/login'}>
            <img className="tw-w-full" src={fleetLogo} alt={'Logo Fleet'} />
          </Link>
        </div>
      </div>

      <div className="tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto">
        {children}
      </div>

      {isAuthenticated ? (
        <>
          {clientFeatures?.web_filters_available_if_any?.filters?.length > 0 &&
          clientFeatures.web_filters_available_if_any.filters?.includes(
            'FACTORY_NAME'
          ) ? (
            <>
              <div className="tw-flex tw-flex-center tw-justify-center tw-flex-row tw-mb-5 tw-px-2">
                {ownUserIsLoading ? (
                  <Skeleton
                    width="200px"
                    height="40px"
                    style={{
                      borderRadius: '5px',
                      boxShadow: 'none',
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    }}
                  />
                ) : (
                  <SelectFilter
                    onChange={changeUserFactory}
                    options={[
                      { value: '', name: 'Toutes' },
                      { value: 'PLESTAN', name: 'Plestan' },
                      { value: 'PLOUNERIN', name: 'Plounérin' },
                      { value: 'VITRE', name: 'Vitré' },
                    ]}
                    theme="alternative"
                    icon={faBuilding}
                    width={200}
                    value={factory}
                  />
                )}
              </div>
            </>
          ) : null}

          <div className="tw-border-solid tw-border-0 tw-border-t tw-border-white/50 tw-flex tw-flex-row">
            <div
              className="hover:tw-bg-main-hover active:tw-bg-main-active tw-cursor-pointer tw-text-sm tw-text-white tw-flex tw-w-1/2 tw-items-center tw-justify-center tw-px-3 tw-py-3 tw-border-solid tw-border-0 tw-border-r tw-border-white/50"
              onClick={signOut}
            >
              Déconnexion
            </div>
            <div
              className="hover:tw-bg-main-hover active:tw-bg-main-active tw-cursor-pointer tw-text-sm tw-text-white tw-flex tw-w-1/2 tw-items-center tw-justify-center tw-px-3 tw-py-3"
              onClick={() => navigate('/help')}
            >
              Aide
            </div>
          </div>
        </>
      ) : null}
      {}
    </div>
  );
}
