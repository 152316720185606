import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Menu from './components/fleet-menu/index.js';
import { NavigationSection } from './components/fleet-menu/navigation-section.js';
import { ROLE_ENUM } from './enums/role-enum.enum.js';
import { logout, setConfiguration, setFeatures } from './reducer/actions.js';
import { navigationRoutes } from './routes.js';
import { RouteRenderer } from './routes-renderer.js';

const checkAuth = () => {
  return localStorage.getItem('fleetToken') != undefined;
};

export default function App() {
  const config = useSelector((state) => state.config);
  const features = useSelector((state) => state.features);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = checkAuth();

  useEffect(() => {
    if (dispatch) {
      const token = localStorage.getItem('fleetToken');
      let decoded = token ? jwtDecode(token) : null;

      if (decoded) {
        if (decoded.user?.version !== '1.0.1') {
          logoutAction();
        }
        dispatch(
          setConfiguration({
            is_admin: decoded?.user?.roles?.includes(ROLE_ENUM.ADMIN),
            is_super_admin: decoded?.user?.roles?.includes(
              ROLE_ENUM.SUPER_ADMIN
            ),
            can_upload_tour_with_excel:
              decoded?.client?.features?.can_upload_tour_with_excel,
            can_access_dashboard_v2:
              decoded?.client?.features?.can_access_dashboard_v2,
            fleet_optimization: decoded?.client?.features?.fleet_optimization,
            logo_url: decoded?.client?.logo_url,
            client_id: decoded?.user?.client_id,
          })
        );
        dispatch(setFeatures(decoded.client.features));
      }
    }
  }, [dispatch]);

  const logoutAction = () => {
    localStorage.removeItem('fleetToken');
    localStorage.removeItem('fleetClientId');
    dispatch(logout());

    navigate('/login');
  };

  return (
    <div className="App">
      <nav className="App-nav">
        <Menu isAuthenticated={isAuthenticated}>
          {navigationRoutes
            .filter(
              (section) => section.need_authentication === isAuthenticated
            )
            .map((section) => (
              <NavigationSection
                key={section.title}
                title={section.title}
                section={section}
                routes={section.routes}
                config={config}
                features={features}
              />
            ))}
        </Menu>
      </nav>
      <section className="App-frame">
        <RouteRenderer
          config={config}
          features={features}
          isAuthenticated={isAuthenticated}
        />
      </section>
    </div>
  );
}
