import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const NavigationSection = ({
  title,
  routes,
  section,
  config,
  features,
}) => {
  const location = useLocation();

  const filteredRoutes = routes.filter((route) => {
    if (section?.config_required && !config?.[section.config_required])
      return false;
    if (route.config_required && !config?.[route.config_required]) return false;
    if (route.feature_required && !features?.[route.feature_required])
      return false;
    return true;
  });

  if (filteredRoutes.length === 0) return null;

  return (
    <div className="tw-mt-8 tw-px-2">
      <div className="tw-border-solid tw-border-0 tw-border-b tw-border-white/50 tw-pb-3">
        <div className="tw-text-[11px] tw-leading-[13px] tw-text-text-light-high-importance tw-font-work tw-font-medium tw-mb-3 tw-ml-2">
          {title}
        </div>
        {filteredRoutes.map((route, index) => (
          <Link
            key={`${route.url_path}${index}`}
            className="tw-no-underline"
            to={route.url_path}
          >
            <div
              className={`tw-rounded-md tw-cursor-pointer tw-mb-[3px] tw-px-3 tw-py-2 ${
                location.pathname === route.url_path
                  ? 'tw-bg-main-active'
                  : 'hover:tw-bg-main-hover'
              }`}
            >
              <div className="tw-text-sm tw-font-work tw-text-text-light-high-importance">
                {route.section_title}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
