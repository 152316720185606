import HomeV3 from './pages/HomeV3';
import Help from './pages/Help/index.js';
import Admin from './pages/Admin/index.js';
import Documents from './pages/Documents/index.js';
import FleetManagement from './pages/FleetManagement/index.js';
import Forgot from './pages/Forgot/index.js';
import Login from './pages/Login/index.js';
import Messaging from './pages/Messaging/index.js';
import Reserves from './pages/Reserves/index.js';
import ResetPassword from './pages/ResetPassword/index.js';
import Tour from './pages/Tour/index.js';
import Users from './pages/Users/index.js';
import Validation from './pages/Validation/index.js';
import Vehicles from './pages/Vehicles/index.js';
import AddFile from './pages/__OLD__/AddFile/index.js';

export const navigationRoutes = [
  {
    title: 'CONNEXION',
    need_authentication: false,
    routes: [
      {
        url_path: '/login',
        section_title: 'Login',
        component: Login,
      },
      {
        url_path: '/help',
        section_title: 'Help',
        component: Help,
      },
    ],
  },
  {
    title: 'PILOTAGE',
    need_authentication: true,
    routes: [
      {
        url_path: '/',
        section_title: 'Tableau de bord',
        component: HomeV3,
        config_required: 'can_access_dashboard_v2',
      },
      {
        url_path: '/tours',
        section_title: 'Tournées',
        component: Tour,
      },
      {
        url_path: '/vehicles',
        section_title: 'Véhicules',
        component: Vehicles,
      },
      {
        url_path: '/reserves',
        section_title: 'Réserves',
        component: Reserves,
      },
      {
        url_path: '/messaging',
        section_title: 'Messagerie',
        component: Messaging,
      },
    ],
  },
  {
    title: 'GESTION',
    need_authentication: true,
    config_required: 'is_admin',
    routes: [
      {
        url_path: '/validation',
        section_title: 'Validation',
        component: Validation,
        feature_required: 'checklist',
      },
      {
        url_path: '/users',
        section_title: 'Utilisateurs',
        component: Users,
      },
      {
        url_path: '/documents',
        section_title: 'Documents',
        component: Documents,
      },
      {
        url_path: '/invoices',
        section_title: 'Factures',
        component: Admin,
      },
      {
        url_path: '/addfile',
        section_title: 'Import Excel',
        component: AddFile,
        config_required: 'can_upload_tour_with_excel',
      },
    ],
  },
];

export const technicalRoutes = [
  {
    url_path: '/login',
    component: Login,
    public: true,
  },
  {
    url_path: '/help',
    component: Help,
    public: true,
  },
  {
    url_path: '/forgot',
    component: Forgot,
    public: true,
  },
  {
    url_path: '/reset-password/:token',
    component: ResetPassword,
    public: true,
  },
  {
    url_path: '/tour/:id',
    component: Tour,
  },
  {
    url_path: '/fleet-management',
    component: FleetManagement,
  },
];
