import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import iconHelp from '../../assets/images/icons/icon-help.png';
import './help.css';

export default function Help() {
  return (
    <Container style={{ width: '100%' }}>
      <div className="img-fix" />
      <h2 className="text-center py-4 title">Centre d'aide</h2>
      <Row>
        <Col lg={6}>
          {/* first help */}
          <div className="d-flex align-items-center">
            <img className="icon-help" src={iconHelp} alt="faq" />
            <h4 className="p-0 subtitle">À quoi sert le Back-office ?</h4>
          </div>
          <p className="mt-3">
            Avec le Back-office vous avez la possibilité de :
          </p>
          <ul>
            <li>Obtenir les informations de livraisons.</li>
            <li>Suivre les métriques en direct.</li>
            <li>Optimiser vos livraisons.</li>
          </ul>
          {/* second help */}
          <div className="d-flex align-items-center">
            <img className="icon-help" src={iconHelp} alt="faq" />
            <h4 className="p-0 subtitle">
              Comment changer le code PIN d'un véhicule ?
            </h4>
          </div>
          <p className="mt-3">
            Pour cela, rendez-vous dans la section "Véhicules" via le menu de
            gauche.
          </p>
          <ul>
            <li>
              {' '}
              Appuyez sur le bouton " <FontAwesomeIcon icon={faInfo} /> " du
              véhicule souhaité.
            </li>
            <li>
              {' '}
              Modifier le code PIN via le champ en respectant les regles
              suivantes:
            </li>
            <ul>
              <li>Le code doit etre composé uniquement de chiffres.</li>
              <li>Le code doit faire précisement 6 caractères.</li>
            </ul>
            <li>
              Une fois le code modifié, il est enregistré automatiquement.
            </li>
            <li>
              Si un problème survient pendant la modification du PIN, cela vous
              sera signalé par un message d'erreur et le code ne sera pas mis à
              jour.
            </li>
          </ul>
          {/* third help */}
          <div className="d-flex align-items-center">
            <img className="icon-help" src={iconHelp} alt="faq" />
            <h4 className="p-0 subtitle">Peut-on filtrer les tournées ?</h4>
          </div>
          <p className="mt-3">
            {' '}
            Oui, il est possible de filtrer les tournées selon leur statut grâce
            au bouton en haut à droite de l'écran.
          </p>
          <p>
            {' '}
            Pour revenir à une vue d'ensemble, sélectionnez le filtre "Toutes"
            qui affichera toutes les tournées qu'importe leur statut.
          </p>
        </Col>
        <Col lg={6}>
          {/* first help */}
          <div className="d-flex align-items-center">
            <img className="icon-help" src={iconHelp} alt="faq" />
            <h4 className="p-0 subtitle">
              Quelles sont les fonctionnalités du Back-office ?
            </h4>
          </div>
          <p className="mt-3 bold">Onglet Véhicules</p>
          <ul>
            <li>
              La gestion de la flotte de véhicules et des codes PIN attribué à
              chaque véhicule.
            </li>
          </ul>
          <p className="bold">Onglet Dashboard</p>
          <ul>
            <li>
              {' '}
              Consultation en direct des differentes métriques de livraison
              centralisées en un écran.
            </li>
          </ul>
          <p className="bold">Onglet Tournées</p>
          <ul>
            <li>Gestion et consultation des tournées.</li>
            <li>de leurs differentes étapes de livraison.</li>
            <li>les differents BL attachés.</li>
            <li>Filtrer les differentes tournées par leur statut.</li>
          </ul>
          {/* second help */}
          <div className="d-flex align-items-center">
            <img className="icon-help" src={iconHelp} alt="faq" />
            <h4 className="p-0 subtitle">
              Comment fonctionnent les tournées ?
            </h4>
          </div>
          <p className="mt-3">
            Rendez-vous dans la section "Tournées" via le menu de gauche. Vous y
            trouverez les tournées et leur statut.
          </p>
          <p>
            {' '}
            Pour plus de détails sur la tournée, appuyez sur le bouton "{' '}
            <FontAwesomeIcon icon={faInfo} /> " et découvrez :
          </p>
          <ul>
            <ul>
              <li>Les différentes étapes de livraison,</li>
              <li>Leur statut,</li>
              <li>Les adresses,</li>
              <li>Les dates de livraison si disponible.</li>
            </ul>
          </ul>
          <p>
            Pour connaitre la liste des BL(s) livré(s) à cette étape, cliquez
            sur l'étape.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
